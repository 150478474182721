<script setup lang="ts">
import TelefonicaLogo from "@/assets/images/logos/telefonica.svg";
import SiriusLogo from "@/assets/images/logos/sirius.svg";

const route = useRoute();

const hideSiriusLogo = computed(() => {
  switch (route.name) {
    case "authenticate-token":
    case "entrar-token":
      return true;
    default:
      return false;
  }
});
</script>

<template>
  <main class="login-layout">
    <TelefonicaLogo class="login-layout__telefonica" />
    <section class="login-layout__area">
      <SiriusLogo
        class="login-layout__area__logo animate-fadeIn"
        v-if="!hideSiriusLogo"
      />
      <slot />
    </section>
  </main>
</template>

<style scoped lang="scss">
.login-layout {
  @apply w-full h-[100vh] relative flex items-center justify-center;

  &__telefonica {
    @apply w-[200px] absolute top-6 lg:top-[48px] left-6 lg:left-[48px];
  }

  &__area {
    @apply w-full lg:w-[496px] max-w-full flex flex-col items-center p-6 lg:p-0 text-center;

    &__logo {
      @apply mb-[24px] lg:mb-[48px];
    }
  }
}
</style>
